import { View } from "./../types/conf/index";
import * as crud from "@/plugins/utils/Crud";
import * as views from "@/plugins/utils/View";
import i18n from "@/plugins/i18n";
import store from "@/store/index";
import { Route, RouteConfig } from "vue-router";

// #region Mains
import Panel from "@/views/Panel.vue";
import ListView from "@/views/panel/ListView.vue";
import ActiveCalls from "@/views/panel/ActiveCalls.vue";
import About from "@/views/About.vue";
import Corp from "@/views/conf/corp/Corp.vue";
import Salas from "@/views/salas/Salas.vue";
import Calendar from '@/views/salas/Calendar.vue';
import DayCalendar from '@/views/salas/DayCalendar.vue';
import ClickToCall from '@/views/clickToCall/ClickToCall.vue';
import DestinationGroup from "@/views/conf/destinationGroup/DestinationGroup.vue";
import Dialer from "@/views/conf/dialer/Dialer.vue";
import Extensions from "@/views/conf/extensions/Extensions.vue";
import Follow from "@/views/conf/follow/Follow.vue";
import Home from "@/views/Home.vue";
import Logmon from "@/views/logmon/Logmon.vue";
import Integration from "@/views/conf/integrations/Integration.vue";
import IVRs from "@/views/conf/IVRs/IVRs.vue";
import LookAndFeel from "@/views/conf/LookAndFeel.vue";
import MediaServer from "@/views/conf/mediaServer/MediaServer.vue";
import MOH from "@/views/conf/moh/MOH.vue";
import Polls from "@/views/conf/polls/Polls.vue";
import Queue from "@/views/conf/queues/Queue.vue";
import QueueMOH from "@/views/conf/queues/QueueMOH.vue";
import RestrictionsCorp from "@/views/conf/restrictions/RestrictionsCorp.vue";
import RestrictionsExten from "@/views/conf/restrictions/RestrictionsExten.vue";
import RoleGroup from "@/views/roleGroup/RoleGroup.vue";
import Servers from "@/views/conf/servers/Servers.vue";
import SIPRoutes from "@/views/conf/siproutes/SIPRoutes.vue";
import SpeedDial from "@/views/conf/speedDial/SpeedDialCorps.vue";
import SpeedDialExten from "@/views/conf/speedDial/SpeedDialExten.vue";
import Time from "@/views/conf/time/Time.vue";
import Trunks from "@/views/conf/trunks/Trunks.vue";
import UserProfile from "@/views/conf/UserProfile.vue";
import Users from "@/views/conf/users/User.vue";
import VoiceMail from "@/views/conf/voiceMail/VoiceMail.vue";

// #region Details (new, update, delete)
import CorpDetail from "@/views/conf/corp/CorpDetail.vue";
import DestinationGroupDetail from "@/views/conf/destinationGroup/DestinationGroupDetail.vue";
import DialerDetail from "@/views/conf/dialer/DialerDetail.vue";
import ExtensionsDetail from "@/views/conf/extensions/ExtensionsDetail.vue";
import FollowDetail from "@/views/conf/follow/FollowDetail.vue";
import IntegrationDetail from "@/views/conf/integrations/IntegrationDetail.vue";
import IVRsDetail from "@/views/conf/IVRs/IVRsDetail.vue";
import MediaServerDetail from "@/views/conf/mediaServer/MediaServerDetail.vue";
import PollsDetail from "@/views/conf/polls/PollsDetail.vue";
import QuestionsDetail from "@/views/conf/polls/QuestionsDetail.vue";
import RestrictionsCorpDetail from "@/views/conf/restrictions/RestrictionsCorpDetail.vue";
import RestrictionsExtenDetail from "@/views/conf/restrictions/RestrictionsExtenDetail.vue";
import RoleGroupDetail from "@/views/roleGroup/RoleGroupDetail.vue";
import ServersDetail from "@/views/conf/servers/ServersDetail.vue";
import SIPRoutesDetail from "@/views/conf/siproutes/SIPRoutesDetail.vue";
import SpeedDialDetail from "@/views/conf/speedDial/SpeedDialCorpsDetail.vue";
import SpeedDialExtenDetail from "@/views/conf/speedDial/SpeedDialExtenDetail.vue";
import TimeDetail from "@/views/conf/time/TimeDetail.vue";
import TrunksDetail from "@/views/conf/trunks/TrunksDetail.vue";
import UserDetail from "@/views/conf/users/UserDetail.vue";
import Monitor from "@/views/inDisuse/Monitor.vue";

import CDRStatisticsSearch from "@/views/Statistics/CDRStatistics/CDRStatisticsSearch.vue";
import QueuesStatisticsSearch from "@/views/Statistics/QueuesStatistics/QueuesStatisticsSearch.vue";
import PollsStatisticsSearch from "@/views/Statistics/PollsStatistics/PollsStatisticsSearch.vue";
import IVRStatisticsSearch from "@/views/Statistics/IVRStatistics/IVRStatisticsSearch.vue";

const userLevel = store.state.auth.user;

let hasPermission = (viewName: string, action: string) => {
  const views = store.getters["auth/views"];
  const view = views.find((view: View) => view.name == viewName);
  if (view) {
    if (view.actions.includes(action)) {
      return true;
    }
  }
  return false;
};

export const routes: RouteConfig[] = [
  // #region Main
  {
    path: "configuration/home",
    name: "home",
    component: Home,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("userLevel") == "4") {
        return next({
          name: "extension",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },

  {
    path: "configuration/logmon",
    name: "logmon",
    component: Logmon,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },

  {
    path: "configuration/about",
    name: "about",
    component: About,
    beforeEnter: (to: Route, from: Route, next: Function) => {
     const user = store.state.auth.user;
     
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  // #endregion
  // #region CDRs
  {
    path: "statistics/cdrStats",
    name: "cdrStats",
    component: CDRStatisticsSearch,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.CDR, crud.READ)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Corp
  {
    path: "configuration/corp",
    component: Corp,
    name: "corp",
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.CORP, crud.READ)) {
        return false;
      }
      next();
    },
  },
  {
    path: "configuration/corp/update",
    name: "corp/update",
    component: CorpDetail,
    props: true,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.CORP, crud.UPDATE)) {
        return false;
      }
      next();
    },
  },
  {
    path: "configuration/corp/new",
    name: "corp/new",
    component: CorpDetail,
    props: true,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.CORP, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Destination Groups
  {
    path: "configuration/destinationGroup",
    name: "destinationGroup",
    component: DestinationGroup,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "salas/Rooms",
    name: "salas",
    component: Salas,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "salas/Calendario",
    name: "calendar",
    component: Calendar,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "clickToCall",
    name: "clickToCall",
    component: ClickToCall,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "salas/DayCalendar",
    name: "dayCalendar",
    component: DayCalendar,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/destinationGroup/update",
    name: "destinationGroup/update",
    props: true,
    component: DestinationGroupDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.DESTINATION_GROUP, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/destinationGroup/new",
    name: "destinationGroup/new",
    props: true,
    component: DestinationGroupDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.DESTINATION_GROUP, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Dialer
  {
    path: "configuration/dialer",
    name: "dialer",
    component: Dialer,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/dialer/update",
    name: "dialer/update",
    props: true,
    component: DialerDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.DIALER, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/dialer/new",
    name: "dialer/new",
    props: true,
    component: DialerDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.DIALER, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Extensions
  {
    path: "configuration/extension",
    name: "extension",
    component: Extensions,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/extension/update",
    name: "extension/update",
    props: true,
    component: ExtensionsDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.EXTENSION, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/extension/new",
    name: "extension/new",
    props: true,
    component: ExtensionsDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.EXTENSION, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Follows
  {
    path: "configuration/extension/follow",
    name: "follow",
    props: true,
    component: Follow,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/extension/follow/update",
    name: "follow/update",
    props: true,
    component: FollowDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.FOLLOW, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/extension/follow/new",
    name: "follow/new",
    props: true,
    component: FollowDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.FOLLOW, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Integrations
  {
    path: "configuration/integration",
    name: "integration",
    component: Integration,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/integration/update",
    name: "integration/update",
    props: true,
    component: IntegrationDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.INTEGRATION, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/integration/new",
    name: "integration/new",
    props: true,
    component: IntegrationDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.INTEGRATION, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region IVRs
  {
    path: "configuration/ivr",
    name: "ivr",
    component: IVRs,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/ivr/update",
    name: "ivr/update",
    props: true,
    component: IVRsDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.IVR, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/ivr/new",
    name: "ivr/new",
    props: true,
    component: IVRsDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.IVR, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Look And Feel
  {
    path: "configuration/lookAndFeel",
    name: "lookAndFeel",
    component: LookAndFeel,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const user = store.getters["auth/user"];
      if (localStorage.getItem("token") === null || user.level > 4 || !user.allowCustom) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  // #endregion
  // #region Media Servers
  {
    path: "configuration/mediaServer",
    name: "mediaserver",
    component: MediaServer,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/mediaServer/update",
    name: "mediaserver/update",
    props: true,
    component: MediaServerDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.MEDIA_SERVERS, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/mediaServer/new",
    name: "mediaserver/new",
    props: true,
    component: MediaServerDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.MEDIA_SERVERS, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region MOH
  {
    path: "configuration/mohCorp",
    name: "mohCorp",
    component: MOH,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  // #endregion
  // #region Polls
  {
    path: "configuration/polls",
    name: "polls",
    component: Polls,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/polls/update",
    name: "polls/update",
    props: true,
    component: PollsDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.POLLS, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/polls/new",
    name: "polls/new",
    props: true,
    component: PollsDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.POLLS, crud.CREATE)) {
        return false;
      }
      next();
    },
  },

  {
    path: "configuration/polls/questions",
    name: "questions",
    props: true,
    component: QuestionsDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.POLLS, crud.READ)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Panel
  {
    path: "panel/dashboard",
    name: "panel",
    component: Panel,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.PANEL, crud.READ)) {
        return false;
      }
      next();
    },
  },
  {
    path: "panel/list",
    name: "list",
    component: ListView,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.PANEL, crud.READ)) {
        return false;
      }
      next();
    },
  },
  {
    path: "panel/activeCalls",
    name: "activeCalls",
    component: ActiveCalls,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.PANEL, crud.READ)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  {
    path: "misc/queues",
    name: "queues",
    component: Queue,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "misc/queues/moh",
    name: "queues/moh",
    props: true,
    component: QueueMOH,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.MOHQUEUE, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  
  // #region Restrictions Corp
  {
    path: "configuration/restrictionsCorp",
    name: "restrictionsCorp",
    component: RestrictionsCorp,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  
  {
    path: "configuration/restrictionsCorp/update",
    name: "restrictionsCorp/update",
    props: true,
    component: RestrictionsCorpDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.RESTRICTION_CORP, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/restrictionsCorp/new",
    name: "restrictionsCorp/new",
    props: true,
    component: RestrictionsCorpDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.RESTRICTION_CORP, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Restrictions Extension
  {
    path: "configuration/restrictionsexten",
    name: "restrictionsExten",
    props: true,
    component: RestrictionsExten,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/extension/restrictionsexten",
    name: "restrictionsexten",
    props: true,
    component: RestrictionsExten,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/extension/restrictionsexten/update",
    name: "restrictionsexten/update",
    props: true,
    component: RestrictionsExtenDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.RESTRICTION_EXTEN, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/extension/restrictionsexten/new",
    name: "restrictionsexten/new",
    props: true,
    component: RestrictionsExtenDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.RESTRICTION_EXTEN, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Role Group
  {
    path: "configuration/rolegroup",
    name: "rolegroup",
    component: RoleGroup,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.ROLEGROUP, crud.READ)) {
        return false;
      }
      next();
    },
  },
  {
    path: "configuration/rolegroup/update",
    name: "rolegroup/update",
    component: RoleGroupDetail,
    props: true,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.ROLEGROUP, crud.UPDATE)) {
        return false;
      }
      next();
    },
  },
  {
    path: "configuration/rolegroup/new",
    name: "rolegroup/new",
    component: RoleGroupDetail,
    props: true,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.ROLEGROUP, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Servers
  {
    path: "configuration/server",
    name: "servers",
    component: Servers,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/server/update",
    name: "servers/update",
    props: true,
    component: ServersDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SERVERS, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/server/new",
    name: "servers/new",
    props: true,
    component: ServersDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SERVERS, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region SIP Routes
  {
    path: "siproute/all",
    name: "siproutes",
    component: SIPRoutes,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const user = store.state.auth.user;
      if (localStorage.getItem("token") === null || user.level !== 1) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "siproute/update",
    name: "siproutes/update",
    props: true,
    component: SIPRoutesDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const user = store.state.auth.user;
      if (localStorage.getItem("token") === null || user.level !== 1) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SIP_ROUTES, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "siproute/new",
    name: "siproutes/new",
    props: true,
    component: SIPRoutesDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const user = store.state.auth.user;
      if (localStorage.getItem("token") === null || user.level !== 1) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SIP_ROUTES, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region SpeedDial Corp
  {
    path: "configuration/speedDialCorp",
    name: "speedDialCorp",
    component: SpeedDial,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (
        localStorage.getItem("token") === null ||
        store.state.auth.user.level < 0
      ) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/speedDialCorp/update",
    name: "speedDialCorp/update",
    props: true,
    component: SpeedDialDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (
        localStorage.getItem("token") === null ||
        store.state.auth.user.level < 0
      ) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SPEED_DIAL_CORP, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/speedDialCorp/new",
    name: "speedDialCorp/new",
    props: true,
    component: SpeedDialDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (
        localStorage.getItem("token") === null ||
        store.state.auth.user.level < 0
      ) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SPEED_DIAL_CORP, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region SpeedDial Extensions
  {
    path: "configuration/extension/speedDialExten",
    name: "speedDialExten",
    props: true,
    component: SpeedDialExten,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/speedDialExten/update",
    name: "speedDialExten/update",
    props: true,
    component: SpeedDialExtenDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SPEED_DIAL_EXTEN, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/speedDialExten/new",
    name: "speedDialExten/new",
    props: true,
    component: SpeedDialExtenDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.SPEED_DIAL_EXTEN, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Statistics
  {
    path: "statistics/pollStats",
    name: "pollStats",
    component: PollsStatisticsSearch,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "statistics/queueStats",
    name: "queueStats",
    component: QueuesStatisticsSearch,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "statistics/ivrStats",
    name: "ivrStats",
    component: IVRStatisticsSearch,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "statistics/monitorQuality",
    name: "monitorQuality",
    component: Monitor,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  // #endregion
  // #region Time
  {
    path: "configuration/time",
    name: "time",
    component: Time,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/time/update",
    name: "time/update",
    props: true,
    component: TimeDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.TIME, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/time/new",
    name: "time/new",
    props: true,
    component: TimeDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.TIME, crud.CREATE)) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region Trunks
  {
    path: "configuration/trunk",
    name: "trunk",
    component: Trunks,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/trunk/update",
    name: "trunk/update",
    props: true,
    component: TrunksDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.TRUNK, crud.UPDATE)) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/trunk/new",
    name: "trunk/new",
    props: true,
    component: TrunksDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (
        store.state.auth.user.level !== 1 ||
        !hasPermission(views.TRUNK, crud.CREATE)
      ) {
        return false;
      }
      next();
    },
  },
  // #endregion
  // #region User
  {
    path: "configuration/user",
    name: "user",
    component: Users,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const user = store.getters["auth/user"];
      if (localStorage.getItem("token") === null || user.level === 4) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  {
    path: "configuration/user/update",
    name: "user/update",
    props: true,
    component: UserDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const user = store.getters["auth/user"];
      if (localStorage.getItem("token") === null || user.level === 4) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }

      if (!hasPermission(views.USERS, "update")) {
        return false;
      }

      next();
    },
  },
  {
    path: "configuration/user/new",
    name: "user/new",
    props: true,
    component: UserDetail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const user = store.getters["auth/user"];
      if (localStorage.getItem("token") === null || user.level === 4) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.USERS, "create")) {
        return false;
      }
      next();
    },
  },
  {
    path: "configuration/user/userProfile",
    name: "user/userProfile",
    component: UserProfile,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      next();
    },
  },
  // #endregion
  // #region VoiceMail
  {
    path: "configuration/voiceMail",
    name: "voiceMail",
    component: VoiceMail,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (localStorage.getItem("token") === null) {
        return next({
          name: "login",
          params: {
            corp: store.state.lookAndFeel.corpName || "supervisor",
          },
        });
      }
      if (!hasPermission(views.VOICE_MAIL, crud.READ)) {
        return false;
      }
      next();
    },
  },
  // #endregion
];